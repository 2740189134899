export const menuItems = [
    
    {
        id: 'AHOME',
        label: 'menuitems.main.text',
        icon: 'uil-home-alt',
        link: '/'
    },
    
    {
        id: 'CRM00000',
        label: 'menuitems.customer.text',
        icon: 'uil-smile-squint-wink',
        subItems: [
            {
                id: 'CRM01000',
                label: 'menuitems.customer.list.maincus',
                link:'/customer/maincus',
            },
            {
                id: 'CRM02000',
                label: 'menuitems.mycars.list.vehiclelist',
                link: '/vehiclelist',
            },
            {
                id: 'CRM03000',
                label: 'menuitems.customer.list.appointment',
                link:'/appointment',
            },{
                id: 'CRM04000',
                label: 'menuitems.customer.list.flowslotCRM',
                link:'/flowslotCRM',
            },
            {
                id: 'CRM05000',
                label: 'menuitems.customer.list.message',
                link:'/contactMessage',
            },
            {
                id: 'CRM06000',
                label: 'menuitems.customer.list.repairHis',
                link:'/repair-history',
            },
            {
                id: 'CRM07000',
                label: 'menuitems.customer.list.prospectCustomer',
                link:'/prospect-customer',
            },
            {
                id: 'CRM08000',
                label: 'menuitems.customer.list.contactCustomer',
                link:'/contact-job',
            },
            {
                id: 'CRM09000',
                label: 'menuitems.customer.list.customer-map',
                link:'/customer-map',
            },
            {
                id: 'CRM10000',
                label: 'menuitems.customer.list.crmLine',
                link:'/crmLine',
            },
        ],
    },
    {
        id: 'SEL00000',
        label: 'menuitems.Sales.text',
        icon: 'uil-car',
        subItems: [
            {
                id:'SEL02000',
                label: 'menuitems.Sales.list.product-sales',
                link: '/product-sales',
            },
            {
                id:'SEL10000',
                label: 'menuitems.Sales.list.d-product-sell',
                link: '/d-product-sell',
            },
            
            {
                id:'SEL11000',
                label: 'menuitems.Sales.list.vehicle-booking',
                link: '/vehicle-booking',
            },
            {
                id:'SEL12000',
                label: 'menuitems.Sales.list.credit-inquire',
                link: '/credit-inquire',
            },
            {
                id:'SEL14000',
                label: 'menuitems.Sales.list.vehicle-sale',
                link: '/vehicle-sale',
            
            },
            {
                id:'SEL13000',
                label: 'menuitems.Sales.list.approve-credit-inquire',
                link: '/approve-credit-inquire',
            },
            {
                id:'SEL15000',
                label: 'menuitems.Sales.list.vehicle-apv',
                link: '/vehicle-apv',
            },
            {
                id:'SEL17000',
                label: 'menuitems.Sales.list.d-stock-vehicle',
                link: '/d-stock-vehicle',
            },
            {
                id:'SEL18000',
                label: 'menuitems.Sales.list.vehicle-audit',
                link: '/vehicle-audit',
            }
            
        ]
    },
    {
        id: 'SRV00000',
        label: 'menuitems.mycars.text',
        icon: 'uil-wrench',
        subItems: [
                    {
                        id:'SRV06000',
                        label: 'menuitems.mycars.list.servicelist',
                        link: '/servicelist',
                    },
                    {
                        id:'SRV05000',
                        label: 'menuitems.mycars.list.flowslot',
                        link: '/FlowSlot/mainFlowSlot',
                        
                    },
                    {
                        id:'SRV05001',
                        label: 'menuitems.mycars.list.FlowSlotDRT',
                        link: '/FlowSlot/FlowSlotDRT',
                    },
                    {
                        id:'SRV03000',
                        label: 'menuitems.mycars.list.statusBoard',
                        link: '/statusBoard/mainStatusBoard',
                    },
                    {
                        id:'SRV03001',
                        label: 'menuitems.mycars.list.statusBoardAds',
                        link: '/statusBoard/mainStatusBoardAds',
                    },
                    {
                        id:'SRV07000',
                        label: 'menuitems.mycars.list.welcomeBoard',
                        link: '/statusBoard/welcomeBoard',
                    },
                    {
                        id:'SRV08000',
                        label: 'menuitems.mycars.list.outsourcing',
                        link: '/main-outsourcing',
                    },
                    {
                        id:'SRV09000',
                        label: 'menuitems.mycars.list.received-outsourcing',
                        link: '/main-received-outsourcing',
                    },
                    {
                        id:'SRV10000',
                        label: 'menuitems.mycars.list.main-diag-car',
                        link: '/main-diag-car',
                    },
                    {
                        id:'SRV06001',
                        label: 'menuitems.mycars.list.ro-cancellation',
                        link: '/ro-cancellation',
                    },
                    {
                        id:'SRV11000',
                        label: 'menuitems.mycars.list.quotation',
                        link: '/quotations',
                    }

        ],
        link: '/mycars'
    },
    {
        id: 'STCK00000',
        label: 'menuitems.Master.stock',
        icon: 'uil-box',
        subItems: [
            {
                id:'STCK01000',
                label: 'menuitems.Sales.list.products',
                link: '/products',
            },
            {
                id:'STCK02000',
                label: 'menuitems.Sales.list.stock-vehicle',
                link: '/stock-vehicle',
            },
            {
                id:'STCK03000',
                label: 'menuitems.Sales.list.vehicle-po',
                link: '/vehicle-po',
            },
            {
                id:'STCK04000',
                label: 'menuitems.Sales.list.received-vehicle',
                link: '/received-vehicle',
            },
            {
                id:'STCK05000',
                label: 'menuitems.Sales.list.vehicle-transfer',
                link: '/vehicle-transfer',
            },
            {
                id:'STCK06000',
                label: 'menuitems.Sales.list.approve-vehicle-transfer',
                link: '/approve-vehicle-transfer',
            },
            {
                id:'STCK07000',
                label: 'menuitems.Sales.list.received-vehicle-transfer',
                link: '/received-vehicle-transfer',
            },
        ]

    },
    {
        id: 'PRT00000',
        label: 'menuitems.parts.text',
        icon: 'uil-box',
        subItems: [
        
                    {
                        id:'PRT01000',
                        label: 'menuitems.parts.list.form',
                        link: '/spare-part',
                    },
                    {
                        id: 'PRT02000',
                        label: 'menuitems.parts.list.purchase-order',
                        link: '/purchase-order',
                    },
                  
                    {
                        id: 'PRT03000',
                        label: 'menuitems.parts.list.recieve-inventory',
                        link: '/parts/recieve-inventory',
                    },
                    {
                        id: 'PRT04000',
                        label: 'menuitems.parts.list.part-requisition',
                        link: '/part-requisition',
                    },
                    {
                        id: 'PRT05000',
                        label: 'menuitems.parts.list.part-picking',
                        link: '/parts/part-picking',
                    },
                    
                    {
                        id: 'PRT06000',
                        label: 'menuitems.parts.list.returnPart',
                        link: '/parts/returnPart',
                    },
                    {
                        id: 'PRT07000',
                        label: 'menuitems.parts.list.stockPart',
                        link: '/parts/stockPart',
                    },
                    {
                        id: 'PRT08000',
                        label: 'menuitems.parts.list.partMonitor',
                        link: '/parts/partMonitor',
                    },
                    {
                        id: 'PRT09000',
                        label: 'menuitems.parts.list.partLostSale',
                        link: '/part-lost-sale',
                    },
        ],
    
    },
    {
        id: 'REP00000',
        label: 'menuitems.report.text',
        icon: 'uil-document-layout-left',
        link: '/report',
    },
        
    {
        id: 'FIN00000',
        label: 'menuitems.Fin.text',
        icon: 'uil-money-stack',
        link: '/',
        subItems: [
            {
                id:'FIN01000',
                label: 'menuitems.Fin.list.taxInvoice',
                link: '/tax-invoice',
            },
            {
                id:'FIN03000',
                label: 'menuitems.Fin.list.Invoice',
                link: '/invoices',
            },
            {
                id:'FIN04000',
                label: 'menuitems.Fin.list.payMent',
                link: '/payments',
            },
            {
                id:'FIN05000',
                label: 'menuitems.Fin.list.billings',
                link: '/billings',
            },
            {
                id:'FIN02000',
                label: 'menuitems.Fin.list.addFinishRo',
                link: '/add-finish',
            },
            {
                id:'FIN06000',
                label: 'menuitems.Fin.list.salesTax',
                link: '/sales-tax',
            },
            {
                id:'FIN07000',
                label: 'menuitems.Fin.list.travel-expenses',
                link: '/travel-expenses',
            },
            
            
        ]
    },
    {
        id: 'REG00000',
        label: 'menuitems.Reg.text',
        icon: 'uil-books',
        link: '/',
        subItems: [
            {
                id:'REG01000',
                label: 'menuitems.Reg.list.receiveDoc',
                link: '/receive-docCus',
            },
            {
                id:'REG02000',
                label: 'menuitems.Reg.list.deliverDoc',
                link: '/deliver-doc',
            },
            {
                id:'REG03000',
                label: 'menuitems.Reg.list.receiveDocBranch',
                link: '/receiveDoc-branch',
            },
            {
                id:'REG04000',
                label: 'menuitems.Reg.list.sendDocReg',
                link: '/send-docReg',
            },
            {
                id:'REG05000',
                label: 'menuitems.Reg.list.receiveDocReg',
                link: '/receive-docReg',
            },
            {
                id:'REG06000',
                label: 'menuitems.Reg.list.sendDocBranch',
                link: '/send-docBranch',
            },
            {
                id:'REG07000',
                label: 'menuitems.Reg.list.branchReceiveDoc',
                link: '/branch-receiveDoc',
            },
            {
                id:'REG08000',
                label: 'menuitems.Reg.list.sendDocCus',
                link: '/send-docCus',
            },
            
        ]
    },

    {
        id: 'SET00000',
        label: 'menuitems.Master.setup',
        icon: 'uil-shop',
        subItems: [
            {
                id: 'SET01000',
                label: 'menuitems.Master.service',
                subItems: [

                    {
                        id: 'SET01001',
                        label: 'menuitems.Master.list.wagesBranch',
                        link:'/branch-wages',
                     },
                    {
                        id: 'SET01002',
                        label: 'menuitems.Master.list.Package',
                        link:'/package/mainPackage',
                    },
                    {
                        id: 'SET01003',
                        label: 'menuitems.Master.list.Model',
                        link:'/vehiclemodels',
                    },
                    {
                        id: 'SET01005',
                        label: 'menuitems.Master.list.paymentMethod',
                        link:'/payment-methods',
                    },
                    {
                        id:'SET01004',
                        label: 'menuitems.Master.list.outsourcing-service',
                        link: '/outsourcing-service',
                    },
                    {
                        id:'SET01006',
                        label: 'menuitems.Master.list.extra-service',
                        link: '/extra-service',
                    },
                    
                    
                ],
            },
            {
                id: 'SET02000',
                label: 'menuitems.Master.HumanResources',
                subItems: [
                    {
                    id: 'SET02001',
                    label: 'menuitems.Master.list3.Users',
                    link:'/main-user',
                    },
                ]
                
            },
            {
                id: 'SET03000',
                label: 'menuitems.Master.Corporate',
                subItems: [

                    {
                        id: 'SET03002',
                        label: 'menuitems.Master.list4.Dealer',
                        link:'/dealer/mainDealer',
                    },
                    {
                        id: 'SET03003',
                        label: 'menuitems.Master.list4.DealerBranch',
                        link:'/dealer/mainDealerBranch',
                    },
                ]
                
            },
            {
                id: 'SET04000',
                label: 'menuitems.Master.MasterData',
                subItems: [
                    {
                        id: 'SET04001',
                        label: 'menuitems.Master.list.dealer-financial-institution',
                        link: '/dealer-financial-institution',
                    },
                    {
                        id: 'SET04002',
                        label: 'menuitems.Master.list.product-type',
                        link: '/product-type',
                    },
                    {
                        id: 'SET04003',
                        label: 'menuitems.Master.list.d-activity',
                        link: '/d-activity',
                    },
                    {
                        id: 'SET04004',
                        label: 'menuitems.Master.list.d-campaign',
                        link: '/d-campaign',
                    },
                    {
                        id: 'SET04005',
                        label: 'menuitems.Master.list.d-customer-source',
                        link: '/d-customer-source',
                    },
                    {
                        id: 'SET04006',
                        label: 'menuitems.Master.list.d-occupation',
                        link: '/d-occupation',
                    },
                    {
                        id: 'SET04007',
                        label: 'menuitems.Master.list.d-exterior-color',
                        link: '/d-exterior-color',
                    },
                    {
                        id: 'SET04008',
                        label: 'menuitems.Master.list.d-interior-color',
                        link: '/d-interior-color',
                    },
                    {
                        id: 'SET04009',
                        label: 'menuitems.Master.list.d-document-type',
                        link: '/document-type',
                    },
                    {
                        id: 'SET04010',
                        label: 'menuitems.Master.list.d-usability',
                        link: '/d-usability',
                    },
                    {
                        id: 'SET04011',
                        label: 'menuitems.Master.list.d-person',
                        link: '/d-person',
                    },
                    {
                        id: 'SET04012',
                        label: 'menuitems.Master.list.d-vehicle-model',
                        link: '/d-vehicle-model',
                    },
                    
                    
                ]
                
            },
            
        ],
       
    
    },

    
]